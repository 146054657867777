import { extend } from 'vee-validate';
import { required, email, min_value, max_value, confirmed } from 'vee-validate/dist/rules';
import { i18n } from '@/main';

extend('field', {
  ...required,
  message: (_, {_field_, _value_}) => {
    return _field_ !== '{field}'
      ? i18n.t('validation.field', {_field_}) as string
      : i18n.tc('validation.required');
  }
});

extend('required', {
  ...required,
  message: () => i18n.tc('validation.required')
});

extend('objectRequired', {
  validate: (value, args) => {
    if (value) {
      if (args.length) {
        if (Object.keys(value).includes(args[0])) {
          return !!value[args[0]];
        }
        if (process.env.NODE_ENV !== 'production') {
          console.warn('The key is not found in value');
        }
      } else {
        return !(Object.keys(value).filter((item) => !value[item]).length);
      }
    }

    return false;
  },
  message: () => i18n.tc('validation.required')
});

extend('email', {
  ...email,
  message: () => i18n.tc('validation.invalid')
});

extend('confirmed', {
  ...confirmed,
  message: () => i18n.tc('validation.invalid')
});

extend('min_value', {
  ...min_value,
  message: (_, {_field_, min}) => i18n.t('validation.min_value', {_field_, min}) as string
});

extend('max_value', {
  ...max_value,
  message: (_, {_field_, max}) => i18n.t('validation.max_value', {_field_, max}) as string
});

extend('number', {
  validate: (value) => /^[0-9]+$/.test(value),
  message: () => i18n.tc('validation.invalid')
});

extend('phoneNumber', {
  validate: (value) => /^[0-9]+$/.test(value.number),
  message: () => i18n.tc('validation.invalid')
});

extend('password', {
  validate: (value) => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\\[\]{}|'])[\w!@#$%^&*()_+\-=\\[\]{}|']{8,128}$/.test(value),
  message: () => i18n.tc('validation.invalid')
});

extend('confirmedPassword', {
  ...confirmed,
  message: () => i18n.tc('validation.invalid')
});

extend('coldRentAmount', {
  validate: (value, input) => value < input,
  message: () => i18n.tc('validation.invalid')
});

extend('phone', {
  validate: (value) => !!(value?.number && (value.number.length > 4)),
  message: () => i18n.tc('validation.invalid')
});

extend('phoneNotRequired', {
  validate: (value) => {
    return value?.number
      ? !(value.number.length >= 1 && value.number.length <= 4)
      : true;
  },
  message: () => i18n.tc('validation.invalid')
});

extend('radioButton', {
  ...required,
  message: () => i18n.tc('validation.unselected')
});

extend('zip', {
  validate: (value) => /^[A-Za-z\d\s-]+$/.test(value) && value.length >= 4 && value.length <= 20,
  message: () => i18n.tc('validation.invalid')
});
